export default defineNuxtRouteMiddleware((to, from) => {
    const { data: authData } = useAuth();
  
    try{
    // verifier si l'utilisateur est authentifié
    if (!authData.value && !authData.value.user) {
      return navigateTo("/"); // Rediriger si non authentifié
    } }
    catch(e){
        return navigateTo("/"); // Rediriger si non authentifié
    }
  });
  